import React, { useContext } from 'react';
import { NetlifyIdentityContext } from 'context';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

const Admin = ({ pageIsPrivate }) => {
  const netlifyIdentityContext = pageIsPrivate && useContext(NetlifyIdentityContext);
  const netlifyIdentity = netlifyIdentityContext?.netlifyIdentity;
  const user = netlifyIdentityContext?.user;

  return (
    <Container>
      <h1>Administrator Dashboard</h1>
      <ul>
        {user?.email === 'admin@agencyx.ie' && (
          <>
            <li>
              <Link to="/admin/non-public-case-studies/">Non-Public Facing Case Studies</Link>
            </li>
            <li>
              <Link to="/admin/isms/" onClick={(e) => e.preventDefault()} style={{ pointerEvents: 'none', cursor: 'default' }}>
                Information Security Management System <em>(Coming Soon)</em>
              </Link>
            </li>
            <li>
              <Link to="/admin/onboarding/">Onboarding</Link>
            </li>
          </>
        )}
        <li>
          <Link to="/logobook/">Logobook</Link>
        </li>
      </ul>
      <LogoutButton type="button" onClick={() => netlifyIdentity.logout()}>
        Logout
      </LogoutButton>
    </Container>
  );
};

const Container = styled.div`
  background: var(--ax-beige);
  position: relative;
  padding: 2em;
  margin: 90px auto 0;
  max-width: 62.5rem;

  a {
    color: #1d1d1b;
    outline: none;
    text-transform: capitalize;
  }

  /* p {
    margin-bottom: 1em;
  } */

  h1 {
    text-align: center;
    margin: 0 auto 1em;
  }

  ul {
    margin-left: 2em;
  }
`;

const LogoutButton = styled(motion.button).attrs({
  whileTap: {
    scale: 0.95
  }
})`
  background-color: var(--ax-gold);
  border-radius: 2px;
  cursor: pointer;
  color: #fff;
  display: block;
  font-weight: bold;
  text-transform: capitalize;
  margin: 1rem auto 0;
  height: 40px;
  width: 80px;
`;

export default Admin;
